import ProductService from "@/services/product-service";

const initialState = { deals: undefined, dealsForUser: undefined, selectedProduct: undefined };

export const products = {
  namespaced: true,
  state: initialState,
  actions: {
    setSelectedProduct({ commit }, payload = null) {
      commit("setSelectedProduct", payload)
    },
    getTokenProducts({ commit }) {
      const products = JSON.parse(localStorage.getItem("products"));
      if (products) {
        commit("getAllTokenProducts", products)
      }

      return ProductService.getAllProducts().then(
        (products) => {
          commit("getAllTokenProducts", products.data);
          localStorage.setItem("products", JSON.stringify(products.data));
          return Promise.resolve(products.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTokenProductsByUser({commit}) {
      const productsByUser = JSON.parse(localStorage.getItem("productsByUser"));
      if (productsByUser) {
        commit("setAllTokenProductsByUser", productsByUser)
      }
      
      return ProductService.getAllProductsByUser().then(
        (productsByUser) => {
          commit("setAllTokenProductsByUser", productsByUser.data)
          localStorage.setItem("productsByUser", JSON.stringify(productsByUser.data))
          return Promise.resolve(productsByUser.data)
        },
        (error) => {
          return Promise.reject(error)
        })
    }
  },
  mutations: {
    setSelectedProduct(state, product) {
      state.selectedProduct = product
    },
    getAllTokenProducts(state, products) {
      state.deals = products;
    },
    setAllTokenProductsByUser(state, products) {
      state.productsByUser = products;
    }
  },
};
