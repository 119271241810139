import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/Home.vue";
import store from ".././store";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import authService from "@/services/auth.service";
import { fingerprint_display } from "@/globals/fpdisplay";

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard",
    },
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Dashboard.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Chart",
    },
    path: "/logs/chart/:id",
    name: "chart",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Chart.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Grouped",
    },
    path: "/logs/grouped/:id",
    name: "grouped",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/GroupedByNames.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard",
    },
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Orders.vue"),
  },
  {
    meta: {
      title: "products",
    },
    path: "/products",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Products.vue"),
  },
  {
    meta: {
      title: "Replenish",
    },
    path: "/replenish",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Replenish.vue"),
  },
  {
    meta: {
      title: "Account Generator Jobs",
    },
    path: "/generation/jobs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Account-generation/Jobs.vue"),
  },
  {
    meta: {
      title: "Affiliate Center",
    },
    path: "/affiliates/index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Affiliate/Index.vue"),
  },
  {
    meta: {
      title: "Logs",
    },
    path: "/logs/:id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Logs.vue"),
  },
  {
    meta: {
      title: "Orders",
    },
    path: "/orders",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Orders.vue"),
  },
  {
    meta: {
      title: "Checkout",
    },
    path: "/checkout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Checkout.vue"),
  },
  {
    meta: {
      title: "View websites",
    },
    path: "/websites",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Websites.vue"),
  },
  {
    meta: {
      title: "Profile",
    },
    path: "/profilev2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Test.vue"),
  },
  {
    meta: {
      title: "Company",
    },
    path: "/company",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Company.vue"),
  },
  {
    meta: {
      title: "Tables",
    },
    path: "/tables",
    name: "tables",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Tables.vue"),
  },
  {
    meta: {
      title: "Forms",
    },
    path: "/forms",
    name: "forms",
    component: () =>
      import(/* webpackChunkName: "forms" */ "@/views/Forms.vue"),
  },
  // {
  //   meta: {
  //     title: 'Profile'
  //   },
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  // },
  {
    meta: {
      title: "Ui",
    },
    path: "/ui",
    name: "ui",
    component: () => import(/* webpackChunkName: "ui" */ "@/views/Ui.vue"),
  },
  {
    meta: {
      title: "Responsive layout",
    },
    path: "/responsive",
    name: "responsive",
    component: () =>
      import(/* webpackChunkName: "responsive" */ "@/views/Responsive.vue"),
  },
  {
    meta: {
      title: "Login",
      fullScreen: true,
    },
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    meta: {
      title: "Register",
      fullScreen: true,
    },
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/RegisterV2.vue"),
  },
  {
    meta: {
      title: "Error",
      fullScreen: true,
    },
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
  },
  {
    meta: {
      title: "Voting Profiles",
    },
    path: "/advanced/profiles",
    name: "Voting Profiles",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/VoteProfile.vue"),
  },
  {
    meta: {
      title: "Proxy Profiles",
    },
    path: "/advanced/profiles/proxy",
    name: "Proxy Profiles",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/ProxyProfile.vue"),
  },
  {
    meta: {
      title: "Invoices",
    },
    path: "/invoices",
    name: "invoices",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Invoices.vue"),
  },
  {
    meta: {
      title: "Support",
    },
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Support.vue"),
  },
  {
    meta: {
      title: "Verify Email",
    },
    path: "/verify",
    name: "verify",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Verify.vue"),
  },
  {
    meta: {
      title: "Timeline",
    },
    path: "/timeline",
    name: "timeline",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Timeline.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((to, from, next) => {

  const text = {
    displayText: null,
    displayType: null,
    displayHeader: null,
  };
  store.dispatch("setDisplayMessage", text);

  const publicPages = ["/login", "/register", "/home", "/landing"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired) {
    store.dispatch("auth/isExpired");
  }

  // if (!to.path.includes("profile") && !to.path.includes("verify") && !store?.state?.auth?.user?.user?.email_verified_at) {
  //   next('/verify')
  // }

  // if (loggedIn) {
  //   authService.getUser()
  // }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {

    /**
     * Fingerprinting
     */
    if (
      store.state &&
      store.state.auth &&
      store.state.auth.user &&
      store.state.auth.user.user
    ) {
      const fingerprintUser = store?.state?.auth?.user?.user?.fingerprint;
      //const scrcl = store?.state?.auth?.user?.user?.scrcl;
      const ip = store?.state?.auth?.user?.user?.ip;

      if (!ip || ip.length <= 0) {
        fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {
            const ip = data.ip;
            authService.setIp(ip).then(() => {
              authService.getUser();
            });
          })
      }

      /**
       * if (!scrcl || scrcl.length <= 0) {
        const fpd = fingerprint_display()

        authService.setScrcl(fpd).then(() => {
          authService.getUser();
        })
      }
       */
      

      if (!fingerprintUser || fingerprintUser.length <= 0) {
        FingerprintJS.load()
          .then((fp) => fp.get())
          .then((result) => {
            const fp = result.visitorId;
            authService.setFingerprint(fp).then(() => {
              authService.getUser();
            });
          });
      }
    }

    next();
  }
});

export default router;
