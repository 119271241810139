

const initialState = {
    groupsList: [],
    currentSelectedGroup: -1
};

export const groups = {
    namespaced: true,
    state: initialState,
    actions: {
        resetSelectedGroup({ commit }, payload = null) {
            commit("setGroupsFilter", -1)
        },
        removeFromSelectedGroup({ commit }, payload = null) {
            commit("removeFromSelectedGroup", payload)
        },
        setFilterByNumber({ commit }, payload = null) {
            commit("setGroupsFilter", payload)
        },
        getGroups({ commit }) {
            const groups = JSON.parse(localStorage.getItem("category_groups_products"));
            if (groups) {
                commit("setCategoryGroups", groups)
            }
        },
        addCategoryToGroup({ commit }, payload = null) {
            commit("addCategoryToGroup", payload)
        },
        addGroup({ commit }, payload = null) {
            commit("addCategoryGroup", {
                id: initialState.groupsList.length + 1,
                name: payload,
                linked_to_orders: []
            })
        }
    },
    mutations: {
        removeFromSelectedGroup(state, payload) {
            const { orderId, categoryId } = payload
            const index = state.groupsList.findIndex((val) => val.id == categoryId)
            if (index > -1) {
                const indexOfOrderId = state.groupsList[index].linked_to_orders.indexOf(orderId)
                if (indexOfOrderId > -1) {
                    state.groupsList[index].linked_to_orders.splice(indexOfOrderId, 1)
                }
            }
            localStorage.setItem("category_groups_products", JSON.stringify(state.groupsList));
        },
        setCategoryGroups(state, names) {
            state.groupsList = names;
        },
        addCategoryToGroup(state, payload) {
            const { categoryId, orderId } = payload
            const index = state.groupsList.findIndex((val) => val.id === categoryId)
            if (index > -1) {
                state.groupsList[index].linked_to_orders.push(orderId)
                localStorage.setItem("category_groups_products", JSON.stringify(state.groupsList));
            }
        },
        addCategoryGroup(state, group) {
            state.groupsList.push(group)
            localStorage.setItem("category_groups_products", JSON.stringify(state.groupsList));
        },
        setGroupsFilter(state, number) {
            state.currentSelectedGroup = number
        }
    },
};
