import NewsService from "@/services/news.service";

const initialState = { };

export const news = {
  namespaced: true,
  state: initialState,
  actions: {
    getTimeline({ commit }) {
      const timeline = JSON.parse(localStorage.getItem("timeline"));
      if (timeline) {
        commit("getTimeline", timeline)
      }
      return NewsService.getTimeline().then(
        (timeline) => {
          commit("getTimeline", timeline.data);
          localStorage.setItem("timeline", JSON.stringify(timeline.data));
          return Promise.resolve(timeline.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getTimeline(state, timeline) {
      state.timeline = timeline;
    },
  },
};
