import axios from "axios";
import authHeader from "./auth-header";
import API_URL from "@/globals/api";
import axiosInstance from "./api";

const MAIL_SERVER = window.location.href.includes("localhost") ? "http://localhost" : "https://email-uuvarjp72q-ew.a.run.app"

class OrderService {
  getAverageSpeed(ids) {
    return axiosInstance.post(`${API_URL}/orders/average`, { ids: ids}, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  updateLimit(id, limit, type) {
    return axiosInstance.patch(`${API_URL}/orders/updateLimit`, { id, max_votes_per_day: limit, type }, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  getGroupedByUsername(id) {
    return axiosInstance.get(`${API_URL}/orders/grouped/usernames/${id}`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  getLogsForGraph(id) {
    return axiosInstance.get(`${API_URL}/orders/graph/${id}`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  getLogsById(id) {
    return axiosInstance.get(`${API_URL}/orders/logs/${id}`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  getOrders() {
    return axiosInstance.get(`${API_URL}/orders/getAll`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  getOrderById(id) {
    return axiosInstance.get(`${API_URL}/orders/get/${id}`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  updateOrder(order) {
    return axiosInstance.post(`${API_URL}/orders/update`, order, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  pauseOrder(order) {
    return axiosInstance.post(`${API_URL}/orders/pause`, order, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  unpauseOrder(order) {
    return axiosInstance.post(`${API_URL}/orders/unpause`, order, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  archive(order) {
    return axiosInstance.post(`${API_URL}/orders/archive`, order, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  unarchive(order) {
    return axiosInstance.post(`${API_URL}/orders/unarchive`, order, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
  }
  reportBroken(email, voteId, form) {
    return axios.post(
      `${MAIL_SERVER}/report`,
      { email, voteId, form },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export default new OrderService();
