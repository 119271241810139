import WebsitesService from "@/services/websites.service";
import store from ".";

const initialState = {
  websites: undefined,
  favorites: [],
  settings: {},
};

const setWebsiteFavorites = (state) => {
  localStorage.setItem("websites_favorites", JSON.stringify(state));
};

export const websites = {
  namespaced: true,
  state: initialState,
  actions: {
    setSetting({commit}, payload = null) {
      commit("setSetting", payload);
    },
    loadSettings({ commit }, payload = null) {
      commit("loadSettings");
    },
    loadFavorites({ commit }, payload = null) {
      commit("loadFavorites");
    },
    removeFromFavorite({ commit }, payload = null) {
      commit("removeFromFavorite", payload);
    },
    addToFavorite({ commit }, payload = null) {
      commit("addToFavorite", payload);
    },
    listWebsites({ commit }) {
      const websites = JSON.parse(localStorage.getItem("websites"));
      if (websites) {
        commit("getAllWebsitesSuccess", websites);
      }
      return WebsitesService.getAllWebsites().then(
        (websites) => {
          commit("getAllWebsitesSuccess", websites.data);
          return Promise.resolve(websites.data);
        },
        (error) => {
          commit("getAllWebsitesFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loadSettings(state, setting) {
      const loaded = JSON.parse(localStorage.getItem("websites_settings"));
      if (loaded) {
        state.settings = loaded;
      }
    },
    setSetting(state, setting) {
      state.settings = { ...state.settings, ...setting };
      localStorage.setItem("websites_settings", JSON.stringify(state.settings));
    },
    loadFavorites(state) {
      const loaded = JSON.parse(localStorage.getItem("websites_favorites"));
      if (!loaded) {
        setWebsiteFavorites([]);
      }
      state.favorites = JSON.parse(localStorage.getItem("websites_favorites"));
    },
    addToFavorite(state, website) {
      const fav = state.favorites;
      if (fav.indexOf(website) < 0) {
        fav.push(website);
        setWebsiteFavorites(fav);
      }
    },
    removeFromFavorite(state, website) {
      const fav = state.favorites;
      const index = state.favorites.indexOf(website);
      if (index >= 0) {
        state.favorites.splice(index, 1);
        setWebsiteFavorites(fav);
      }
    },
    getAllWebsitesSuccess(state, websites) {
      state.websites = websites;
      localStorage.setItem("websites", JSON.stringify(websites));
    },
    getAllWebsitesFailure(websites) {
      state.websites = {};
    },
  },
};
