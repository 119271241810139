import OrderService from "@/services/order.service";

const initialState = {
  selectedOrder: {},
  orders: undefined,
  previousOrdersAlert: undefined,
  loading: false,
};

export const orders = {
  namespaced: true,
  state: initialState,
  actions: {
    toggleLoading({ commit }, payload = null) {
      commit("toggleLoading");
    },
    listOrders({ commit }, payload = null) {
      const jobs = JSON.parse(localStorage.getItem("jobs"));
      if (jobs) {
        commit("getOrdersSuccess", jobs)
      }

      commit("toggleLoading");
      commit("setPreviousOrdersAlert");
      return OrderService.getOrders().then(
        (orders) => {
          commit("toggleLoading");
          commit("getOrdersSuccess", orders.data);
          localStorage.setItem("jobs", JSON.stringify(orders.data));
          return Promise.resolve(orders.data);
        },
        (error) => {
          commit("toggleLoading");
          commit("getOrdersFailure");
          return Promise.reject(error);
        }
      );
    },
    getOrderById({ commit }, payload = null) {
      return OrderService.getOrderById(payload.id).then((orders) => {
        commit("setOrder", orders.data);
        return Promise.resolve(orders.data);
      });
    },
    pauseOrder({ commit }, payload = null) {
      commit("pauseOrder", payload);
    },
    unpauseOrder({ commit }, payload = null) {
      commit("unpauseOrder", payload);
    },
    archive({ commit }, payload = null) {
      commit("archive", payload);
    },
    unArchive({ commit }, payload = null) {
      commit("unArchive", payload);
    },
  },
  mutations: {
    setPreviousOrdersAlert(state) {
      state.previousOrdersAlert = state.orders ? state.orders : undefined;
    },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    getOrdersSuccess(state, orders) {
      state.orders = orders;
    },
    getOrdersFailure(state) {
      // state.orders = {};
    },
    setOrder(state, order) {
      state.selectedOrder = order;
    },
    pauseOrder(state, index) {
      state.orders[index].running = 0;
    },
    unpauseOrder(state, index) {
      state.orders[index].running = 1;
    },
    archive(state, index) {
      state.orders[index].archive = 1;
    },
    unArchive(state, index) {
      state.orders[index].archive = 0;
    },
  },
};
