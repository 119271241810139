import axios from "axios";
import API_URL from "@/globals/api";
import axiosInstance from "./api";

class WebsitesService {

  /**
   * 
   * @returns 
   */
  getAllWebsites() {
    return axiosInstance
      .get(`${API_URL}/orders/getAllWebsites`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
  }

  /**
   * 
   * @returns 
   */
  getSimilarWebsites(ids) {
    return axiosInstance
      .post(`${API_URL}/products/getSuggestions`, { siteIds: ids }, {
        headers: {
          "Content-Type": "application/json"
        },
      })
  }
}

export default new WebsitesService();
