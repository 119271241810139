import API_URL from "@/globals/api";
import authHeader from "./auth-header";
import axiosInstance from "./api";

class NewsService {
  getTimeline() {
    return axiosInstance
      .get(`${API_URL}/news/timeline`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader()
        },
      })
  }
}

export default new NewsService();
