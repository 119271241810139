import { createApp, computed } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { darkModeKey } from "@/config.js";
import setupInterceptors from "./services/setupInterceptors";
import VueNotificationList from "@dafcoe/vue-notification";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faQuestion } from "@fortawesome/free-solid-svg-icons";
import contenteditable from "vue-contenteditable";
import vueCountryRegionSelect from 'vue3-country-region-select'
import Vue3EasyDataTable from 'vue3-easy-data-table';

library.add([faTelegram, faEnvelope, faQuestion]);

import "./css/main.css";

/* Fetch sample data */
// store.dispatch("fetch", "clients");
// store.dispatch("fetch", "history");

/* Dark mode */
const localStorageDarkModeValue = localStorage.getItem(darkModeKey);

const isLoggedIn = computed(() => store.state.auth.status.loggedIn);

const userName = computed(() => isLoggedIn && store?.state?.auth?.user?.user?.name);

if (
  (localStorageDarkModeValue === null &&
    window.matchMedia("(prefers-color-scheme: dark)").matches) ||
  localStorageDarkModeValue === "1"
) {
  store.dispatch("darkMode");
}

/* Default title tag */
const defaultDocumentTitle = `Toplistbot.com User Panel`;

/* Collapse mobile aside menu on route change */
router.beforeEach((to) => {
  store.dispatch("asideMobileToggle", false);
  store.dispatch("asideLgToggle", false);

  /* Full screen mode */
  store.dispatch("fullScreenToggle", !!to.meta.fullScreen);
});

setupInterceptors(store);

router.afterEach((to) => {
  /* Set document title from route meta */
  if (to.meta && to.meta.title && isLoggedIn.value) {
    document.title = `${userName.value} ${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = `${defaultDocumentTitle}`;
  }

  /* Full screen mode */
  store.dispatch("fullScreenToggle", !!to.meta.fullScreen);
});

const app = createApp(App);
// createApp(App).use(store).use(router).mount('#app')
app.use(store);
app.use(router);
app.use(vueCountryRegionSelect);
app.use(Vue3EasyDataTable);
app.use(VueNotificationList);
app.use(contenteditable);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
