import API_URL from "@/globals/api";
import axios from "axios";
import axiosInstance from "./api";

class ProductService {
  getAllProducts() {
    return axiosInstance.get(`${API_URL}/products/tokens`);
  }
  getAllProductsByUser() {
    return axiosInstance.get(`${API_URL}/products/tokensByUser`)
  }

  suggestWebsite(url) {
    return axiosInstance.post(`${API_URL}/products/suggest`, { url });
  }
}

export default new ProductService();
