import axiosInstance from "./api";
import TokenService from "./token.service";
import AuthService from "../services/auth.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (
          (err.response.status === 500 || err.response.status === 401) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;

          try {
            await AuthService.refresh();

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        //  else if (err.response.status === 501) {
        //   //JWT token is no longer valid, can't refresh, so logout
        //   await AuthService.logout();

        //   return axiosInstance(originalConfig);
        // }
        //  else if (
        //   (err.response.status === 500 || err.response.status === 401) &&
        //   originalConfig._retry
        // ) {

        //   const localhost = window.location.href.includes("localhost") ? true : false;

        //   if (!localhost) {
        //     await AuthService.logout();
        //     window.location.href = "https://user.toplistbot.com/#/login"
        //   }

        //   return axiosInstance(originalConfig);
        // }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
