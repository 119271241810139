<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import {
  mdiForwardburger,
  mdiBackburger,
  mdiClose,
  mdiDotsVertical,
  mdiMenu,
  mdiClockOutline,
  mdiCloud,
  mdiCrop,
  mdiAccount,
  mdiCogOutline,
  mdiEmail,
  mdiLogout,
  mdiGithub,
  mdiThemeLightDark,
  mdiRefresh,
  mdiBookOpenVariant,
  mdiTimerOutline,
  mdiWalletOutline,
  mdiAlert
} from "@mdi/js";
import NavBarItem from "@/components/NavBarItem.vue";
import NavBarItemLabel from "@/components/NavBarItemLabel.vue";
import NavBarMenu from "@/components/NavBarMenu.vue";
import NavBarMenuDivider from "@/components/NavBarMenuDivider.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import Icon from "@/components/Icon.vue";
import NavBarSearch from "@/components/NavBarSearch.vue";
import { useRouter } from "vue-router";
import Spinner from "@/components/Spinner.vue";
import AuthService from "../services/auth.service";
import Popper from "vue3-popper";
import discord from "@/assets/images/discord_icon.webp"
import GoogleTranslateSelect from "@google-translate-select/vue3";

const store = useStore();
const router = useRouter();

const toggleLightDark = () => {
  store.dispatch("darkMode");
};

const isNavBarVisible = computed(() => !store.state.isFullScreen);

const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded);

const userName = computed(() => store?.state?.auth?.user?.user?.name);

const tokens = computed(() => store?.state?.auth?.user?.user?.tokens);

const darkMode = computed(() => store.state.darkMode)

const menuToggleMobileIcon = computed(() =>
  isAsideMobileExpanded.value ? mdiBackburger : mdiForwardburger
);

const menuToggleMobile = () => store.dispatch("asideMobileToggle");

const isMenuNavBarActive = ref(false);

const menuNavBarToggleIcon = computed(() =>
  isMenuNavBarActive.value ? mdiClose : mdiDotsVertical
);

const menuNavBarToggle = () => {
  isMenuNavBarActive.value = !isMenuNavBarActive.value;
};

const menuOpenLg = () => {
  store.dispatch("asideLgToggle", true);
};

const logout = () => {
  store.dispatch("auth/logout");
  router.push("/login");
};

// Updating user stats every 15 seconds
const timer = ref(undefined);

onMounted(() => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user.access_token && !user.user) {
    logout();
    return;
  }

  AuthService.getUser();
  timer.value = setInterval(() => AuthService.getUser(), 60000);
});

onUnmounted(() => {
  clearInterval(timer.value);
});

const handleGoogleTranslateSelect = (language) => {
  console.log(language)
}  


const isLoading = computed(() => store.state.auth.loading);
</script>

<template>
  <nav v-show="isNavBarVisible"
    class="top-0 left-0 right-0 fixed flex bg-white h-14 border-b border-gray-100 z-30 w-screen transition-position xl:pl-60 lg:w-auto lg:items-stretch dark:bg-gray-900 dark:border-gray-800"
    :class="{ 'ml-60 lg:ml-0': isAsideMobileExpanded }">
    <div class="flex-1 items-stretch flex h-14">
      <nav-bar-item type="flex lg:hidden" @click.prevent="menuToggleMobile">
        <icon :path="menuToggleMobileIcon" size="24" />
      </nav-bar-item>
      <nav-bar-item type="hidden lg:flex xl:hidden" @click.prevent="menuOpenLg">
        <icon :path="mdiMenu" size="24" />
      </nav-bar-item>
      <!-- <nav-bar-item href="https://discord.gg/fr6BYjJbV6" target="_blank" class="ml-3">
        <Popper :hover="true" content="Join the discord!">
          <img :src="discord" style="width:30px;" />
        </Popper>
      </nav-bar-item> -->
      <nav-bar-item>
        <nav-bar-search />
      </nav-bar-item>
    </div>
    <div class="flex-none items-stretch flex h-14 lg:hidden">
      <nav-bar-item @click.prevent="menuNavBarToggle">
        <icon :path="menuNavBarToggleIcon" size="24" />
      </nav-bar-item>
    </div>
    <div
      class="absolute w-screen top-14 left-0 bg-white shadow lg:w-auto lg:items-stretch lg:flex lg:flex-grow lg:static lg:border-b-0 lg:overflow-visible lg:shadow-none dark:bg-gray-900"
      :class="[isMenuNavBarActive ? 'block' : 'hidden']">
      <div
        class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:ml-auto">


        <nav-bar-item has-divider>
        </nav-bar-item>
        <nav-bar-item has-divider href="https://blog.toplistbot.com/wiki" target="_blank">
          <div :class="`${darkMode ? 'text-white' : 'text-gray-800'} flex`">
            <icon :path="mdiBookOpenVariant" size="24" class="mr-2" /> Wiki
          </div>
        </nav-bar-item>
        <nav-bar-item has-divider>
          <div :class="`${darkMode ? 'text-white' : 'text-gray-800'} flex`">
              <GoogleTranslateSelect
                default-language-code="en"
                default-page-language-code="en"
                :fetch-browser-language="false"
                trigger="click"
                @select="handleGoogleTranslateSelect"
              />
          </div>
        </nav-bar-item>
        <nav-bar-item has-divider>
          <div @click="AuthService.getUser()"
            :class="`${darkMode ? 'text-white' : 'text-gray-800'} inline-flex items-center `">
            <spinner :displayed="isLoading" />
            <Popper :hover="true" :content="`${tokens > 1 ? '' : 'Low Balance'}`">
              <icon v-if="!isLoading" :path="(tokens > 1 ? mdiWalletOutline : mdiAlert)"
                :class="`${tokens <= 1 ? 'text-red-600' : darkMode ? 'text-white' : 'text-gray-600'}`" size="24"
                @click="AuthService.getUser()" />
            </Popper>

            <span class="px-2 text-xs flex flex-col items-center">
              Tokens
              <span class="text-base font-bold">{{ tokens }}</span>
            </span>
          </div>
        </nav-bar-item>

        <nav-bar-menu has-divider>
          <user-avatar :username=userName class="w-6 h-6 mr-3 inline-flex" />
          <div>
            <span>{{ userName }}</span>
          </div>

          <template #dropdown>
            <nav-bar-item to="/profilev2">
              <nav-bar-item-label :icon="mdiAccount" label="My Profile" />
            </nav-bar-item>
            <!-- <nav-bar-item>
              <nav-bar-item-label :icon="mdiCogOutline" label="Settings" />
            </nav-bar-item>
            <nav-bar-item>
              <nav-bar-item-label :icon="mdiEmail" label="Messages" />
            </nav-bar-item> -->
            <nav-bar-menu-divider />
            <nav-bar-item>
              <div @click.prevent="logout">
                <nav-bar-item-label :icon="mdiLogout" label="Log Out" />
              </div>
            </nav-bar-item>
          </template>
        </nav-bar-menu>
        <nav-bar-item has-divider is-desktop-icon-only @click.prevent="toggleLightDark">
          <nav-bar-item-label :icon="mdiThemeLightDark" label="Light/Dark" is-desktop-icon-only />
        </nav-bar-item>
        <nav-bar-item is-desktop-icon-only @click.prevent="logout">
          <nav-bar-item-label :icon="mdiLogout" label="Log out" is-desktop-icon-only />
        </nav-bar-item>
      </div>
    </div>
  </nav>
</template>
