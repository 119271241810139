import AuthService from "../services/auth.service";
import ZapierService from "../services/zapier.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user, loading: false }
  : { status: { loggedIn: false }, user: null, loading: false };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    toggleLoading({ commit }) {
      commit("toggleLoading")
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    updateUser({ commit }, user) {
      commit("toggleLoading");
      commit("updateUser", user);
    },
    isExpired({ commit }, user) {
      AuthService.isTokenExpired();
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    refreshToken({ commit }, accessToken) {
      commit("refreshToken", accessToken);
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {

          ZapierService.create({ username: user.username, email: user.email }).then(() => { })

          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    refreshToken(state, user, access_token) {
      state.status.loggedIn = true;
      state.user = { ...user, access_token: access_token };
    },
    updateUser(state, user) {
      state.user.user = user;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
